import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo } from 'react';
import { useAnalyticsSetPageInfo } from '../../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { useAnalyticsAddUserAttributes } from '../../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsAddUserAttributes';
import { AnalyticsLink } from '../../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import {
  Maybe,
  SortOrders,
  Role,
  User,
  Status,
} from '../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { FeatureFlagSelectors } from '../../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { MachineListActions } from '../../../../../machine-inventory/machine-list/state/machineListActions';
import { UserListActions } from '../../../state/userListActions';
import { UserListSelectors } from '../../../state/UserListSelectors';
import { SentInvitations } from '../../SentInvitations/SentInvitations';
import { getUserListSystemColumns } from './columns/UserListSystem.columns';
import { StyledUserListSystem } from './UserListSystem.styles';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { Table } from 'lib/components/Table/Table';
import { InfiniteScrollConstants } from 'config/constants';
import { DeleteUserModal } from 'app/modules/user-management/modals/components/DeleteUserModal/DeleteUserModal';
import * as UserModalsSelectors from 'app/modules/user-management/modals/state/userModalsSelectors';
import { userModalsActions } from 'app/modules/user-management/modals/state/userModalsSlice';
import { LoadingIndicator } from 'lib/components/LoadingIndicator/LoadingIndicator';
import { selectUserInfo } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';

export const UserListSystem = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isSortingAndFilteringEnabled = features.USER_LIST_FILTERS_AND_SORTING;

  const data = useSelector(UserListSelectors.selectActiveUserData);
  const isLoading = !!useSelector(UserListSelectors.selectActiveUserIsLoading);
  const totalCount = useSelector(UserListSelectors.selectActiveUserTotalCount) || 0;
  const nextPaginationToken = useSelector(UserListSelectors.selectActiveUserNextPaginationToken);
  const isLoadingMoreData = useSelector(UserListSelectors.selectActiveUserIsLoadingMoreData);
  const isVisible = useSelector(UserModalsSelectors.selectDeleteUserModalVisible);
  const userEmail = useSelector(UserModalsSelectors.selectDeleteUserEmail);
  const userName = useMemo(() => data?.find(user => user.email === userEmail)?.displayName || '', [data, userEmail]);
  const dataSource = useMemo(() => data || [], [data]);
  const userLoginInfo = useSelector(selectUserInfo);

  useAnalyticsAddUserAttributes({
    countAuthorized: totalCount,
  });
  useAnalyticsSetPageInfo({});

  useEffect(() => {
    dispatch(
      UserListActions.getUserListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        filter: {
          status: Status.Active,
        },
      })
    );
  }, [dispatch]);

  const loadMore = (): void => {
    dispatch(
      UserListActions.getUserListMoreDataRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: nextPaginationToken,
        },
        filter: {
          status: Status.Active,
        },
      })
    );
  };

  useEffect(
    () => (): void => {
      dispatch(UserListActions.resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isSortingAndFilteringEnabled) return;

    dispatch(UserListActions.getUserListFiltersRequest());
  }, [dispatch, isSortingAndFilteringEnabled]);

  const handleUpdate = ({ id }: User, role: Role): void => {
    const params = {
      id,
      input: {
        role,
      },
    };

    if (role === Role.CustomerAdmin) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.ASSIGN_ADMINISTRATOR_ROLE,
      });
    } else if (role === Role.CustomerStrategicManager) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.ASSIGN_STRATEGIC_MANAGER_ROLE,
      });
    }

    dispatch(UserListActions.setUserRoleRequest(params));
  };

  const handleDelete = (user: Optional<User>): void => {
    if (!user?.email) return;
    dispatch(userModalsActions.showDeleteUserModal({ email: user.email, status: Status.Active }));
  };

  const handleChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(MachineListActions.setActiveSortField(sorter.field as Maybe<string>));
    dispatch(MachineListActions.setActiveSortOrder(sorterOrder as Maybe<SortOrders>));
  };

  const selfEmail = userLoginInfo?.email || '';

  const tableColumns = getUserListSystemColumns({
    t,
    i18n,
    handleUpdate,
    handleDelete,
    selfEmail,
  });

  return (
    <StyledUserListSystem className="user-list-system">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Table
          dataSource={dataSource}
          loading={isLoading}
          className="user-list-system__table"
          columns={tableColumns}
          rowKey="email"
          onChange={handleChange}
          sortDirections={['ascend', 'descend', 'ascend']}
          infiniteScroll={{
            id: 'user-list-infinite-scroll',
            next: loadMore,
            nextPaginationToken,
            isLoadingMoreData,
          }}
          showScrollButtons={true}
        />
      )}
      <br />
      <SentInvitations />
      {isVisible && <DeleteUserModal name={userName || ''} />}
    </StyledUserListSystem>
  );
};
